import {ShippingService} from '../services/ShippingService';
import {OrderService} from '../services/OrderService';
import {BIService} from '../services/BIService';
import {DeliveryType, ProductType, Specs} from '../../components/thankYouPage/constants';
import {PaymentsService} from '../services/PaymentsService';
import {PaymentStatus, Item} from '../../types/app.types';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {DataExtensionsService} from '@wix/wixstores-client-storefront-sdk';

export type IThankYouPageStoreConfig = {
  biService: BIService;
  shippingService: ShippingService;
  orderService: OrderService;
  paymentsService: PaymentsService;
  dataExtensionsService: DataExtensionsService;
  siteStore: SiteStore;
};

export class ThankYouPageStore {
  private readonly biService: BIService;
  private readonly shippingService: ShippingService;
  private readonly orderService: OrderService;
  private readonly paymentsService: PaymentsService;
  private readonly dataExtensionsService: DataExtensionsService;
  private readonly siteStore: SiteStore;

  constructor({
    biService,
    shippingService,
    orderService,
    paymentsService,
    dataExtensionsService,
    siteStore,
  }: IThankYouPageStoreConfig) {
    this.biService = biService;
    this.shippingService = shippingService;
    this.orderService = orderService;
    this.paymentsService = paymentsService;
    this.dataExtensionsService = dataExtensionsService;
    this.siteStore = siteStore;
  }

  private get shouldShowDigitalLinksError() {
    return (
      this.orderService.hasProductsWithType(ProductType.Digital) &&
      this.orderService.hasMissingDigitalLinks() &&
      this.orderService.paymentStatus === PaymentStatus.Paid
    );
  }

  private get digitalItems() {
    return this.orderService.getProductsWithType(ProductType.Digital);
  }

  private get shouldDisplayAdditionalFees() {
    return this.orderService.additionalFees?.length > 0;
  }

  private get shouldShowExtendedFields() {
    return this.siteStore.experiments.enabled(Specs.UseOrderExtendedFields);
  }

  private get shouldHideTaxWhenZeroForGroo() {
    return this.siteStore.experiments.enabled(Specs.HideTaxWhenZeroForGroo);
  }

  private get DontHideTotalsSection() {
    return this.siteStore.experiments.enabled(Specs.DontHideTotalsSection);
  }

  private get hideOrderNumber() {
    return !this.orderService.isValidOrder;
  }

  private readonly reportClickOnLinkOnLineItem = (item: Item) => {
    const [action] = item.actions || [];
    const {concatenatedCatalogAppIds} = this.orderService.extractDataFromItemsForBI();
    return this.biService.thankYouPageClickOnLinkOnLineItemSf({
      orderId: this.orderService.orderGuid,
      cartId: this.orderService.cartId,
      checkoutId: this.orderService.checkoutId,
      catalogItemId: item.productId,
      itemType: item.productType,
      actionName: action?.label ?? 'Download',
      concatenatedCatalogAppIds,
    });
  };

  private readonly getOfflineInstruction = async () => {
    return this.orderService.isOfflinePayment ? this.paymentsService.fetchOfflineInstruction() : null;
  };

  private readonly shouldShowDigitalItems = () => {
    return (
      this.orderService.paymentStatus === PaymentStatus.Paid &&
      this.orderService.hasProductsWithType(ProductType.Digital) &&
      !this.orderService.isOfflinePayment &&
      !this.orderService.hasMissingDigitalLinks()
    );
  };

  private get paymentMethodNames(): string[] {
    return !this.isHavePayments
      ? []
      : this.orderService.payments
          .map((payment) => {
            if (payment.giftcardPaymentDetails) {
              return 'thankYouPage.giftCard.label';
            }
            if (payment.membershipPaymentDetails) {
              return payment.membershipPaymentDetails.name?.original;
            }
            return payment.regularPaymentDetails?.paymentMethod;
          })
          .filter((payment) => payment !== undefined);
  }

  private get isHavePayments(): boolean {
    return !!this.orderService.payments?.length;
  }

  public async toProps() {
    return {
      additionalFees: this.orderService.additionalFees,
      buyerName: this.orderService.buyerName,
      buyerNote: this.orderService.buyerNote,
      isFree: this.orderService.totalPrice === 0,
      digitalItems: this.digitalItems,
      extendedFields: this.orderService.extendedFields
        ? this.dataExtensionsService.getDataExtendedFieldsView(this.orderService.extendedFields)
        : [],
      formattedTotalPrice: this.orderService.formattedTotalPrice,
      formattedTaxPrice: this.orderService.formattedTaxPrice,
      formattedShippingPrice: this.orderService.formattedShippingPrice,
      formattedSubTotalPrice: this.orderService.formattedSubTotalPrice,
      formattedAdditionalFeesPrice: this.orderService.formattedAdditionalFeesPrice,
      formattedDiscount: this.orderService.formattedDiscountPrice,
      formattedTotalGiftCardAmount: this.orderService.formattedTotalGiftCardAmount,
      hasGiftCard: this.orderService.hasGiftCard,
      totalGiftCardAmount: this.orderService.totalGiftCardAmount,
      formattedTotalsPaid: this.orderService.formattedTotalsPaid,
      formattedTotalsBalance: this.orderService.formattedTotalsBalance,
      shouldShowDigitalItems: this.shouldShowDigitalItems(),
      isSubscription: this.orderService.isSubscription,
      isValidOrder: this.orderService.isValidOrder,
      items: this.orderService.items,
      address: this.orderService.shippingAddress,
      pickupAddress: this.orderService.storePickup?.address,
      pickupTitle: this.orderService.storePickup?.title,
      shippingPrice: this.orderService.shippingPrice,
      billingAddress: this.orderService.billingAddress,
      isOfflinePayment: this.orderService.isOfflinePayment,
      offlineInstruction: await this.getOfflineInstruction(),
      orderId: this.orderService.orderId,
      reportClickOnLinkOnLineItem: this.reportClickOnLinkOnLineItem,
      shippingAddress: this.shippingService.getShippingAddress(),
      shouldShowDigitalLinksError: this.shouldShowDigitalLinksError,
      shouldShowOfflinePayments: this.orderService.isValidOrder && this.orderService.isOfflinePayment,
      shouldShowShipping: this.orderService.isValidOrder && this.shippingService.shouldShowShipping(),
      shouldDisplayAdditionalFees: this.shouldDisplayAdditionalFees,
      shouldShowStorePickup: this.orderService.storePickup && this.orderService.deliveryType === DeliveryType.PICKUP,
      storePickup: this.orderService.storePickup,
      isPickupSelected: this.orderService.deliveryType === DeliveryType.PICKUP,
      couponCode: this.orderService.couponCode,
      subscriptionDuration: this.orderService.subscriptionDuration,
      subscriptionFrequency: this.orderService.subscriptionFrequency,
      subscriptionInterval: this.orderService.subscriptionInterval,
      subscriptionName: this.orderService.subscriptionName,
      taxName: this.siteStore.taxName,
      paymentStatus: this.orderService.paymentStatus,
      totalPrice: this.orderService.totalPrice,
      isEditor: this.siteStore.isEditorMode(),
      isPreview: this.siteStore.isPreviewMode(),
      paymentMethodNames: this.paymentMethodNames,
      isHavePayments: this.isHavePayments,
      hideOrderNumber: this.hideOrderNumber,
      deliveryTime: this.orderService.deliveryTime,
      shouldShowExtendedFields: this.shouldShowExtendedFields,
      shouldHideTaxWhenZeroForGroo: this.shouldHideTaxWhenZeroForGroo,
      DontHideTotalsSection: this.DontHideTotalsSection,
      taxPrice: this.orderService.taxPrice,
    };
  }
}
